<template>
	<div :class="classes">
		<header v-html="question"></header>
		<div class="video-background">
			<div v-if="showOverlay" class="video-background-overlay" @click="play"></div>
			<video ref="preview" autoplay muted></video>
			<button type="button" aria-label="Start/Stop recording" @click="record">
				<IconCamera :width="32" :height="32" :strokewidth="2" />
			</button>
		</div>
		<div class="meta">
			<div class="time">
				Time: <span class="value">{{ limit }}</span>
			</div>
			<div v-if="block.meta.nrOfTries" class="tries">
				Tries: <span class="value">{{ tries }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '../../inc/bus';
	import IconCamera from '../icons/IconCamera';
	import uploadMixin from '../../mixins/uploadMixin';
	import ObjectStore from '../../inc/objectStore';
	import {jsonToHtml} from '../../inc/text';
	import {DateTime} from 'luxon';
	import Store from '../../inc/store';

	export default {
		components: {
			IconCamera
		},
		mixins: [uploadMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				recording: false,
				recorder: null,
				videoChunks: [],
				videoBlob: null,
				recordingInteval: null,
				timeLimit: this.block.meta.timeLimit,
				triesRemaning: this.block.meta.nrOfTries,
				showOverlay: false
			};
		},
		computed: {
			limit() {
				const m = Math.floor(this.timeLimit / 60);
				let s = Math.floor(this.timeLimit % 60);

				if(s.toString().length < 2) {
					s = '0' + s;
				}

				return m + ':' + s;
			},
			tries() {
				return this.block.meta.nrOfTries ? this.triesRemaning + '/' + this.block.meta.nrOfTries : '';
			},
			classes() {
				return 'block-inner' + (this.recording ? ' recording' : '') + (this.showOverlay ? ' has-video' : '') + (this.videoBlob && this.$refs.preview.playing ? ' playing' : '');
			},
			allowedFileTypes() {
				return ['video/webm'];
			},
			question() {
				return jsonToHtml(this.block.content);
			}
		},
		watch: {
			answer() {
				if(this.answer && this.answer.files) {
					this.triesRemaning -= this.answer.files.length;
					this.triesRemaning = Math.max(0, this.triesRemaning);

					const file = this.answer.files[this.answer.files.length - 1];

					ObjectStore.getFileUrl(file).then(url => {
						this.$refs.preview.autoplay = false;
						this.$refs.preview.src = url;
						this.showOverlay = true;
					});
				}
			}
		},
		methods: {
			setupVideoRecorder() {
				return navigator.mediaDevices.getUserMedia({
					audio: true,
					video: true
				}).then(stream => {
					this.$refs.preview.srcObject = stream;
					this.$refs.preview.captureStream = this.$refs.preview.captureStream || this.$refs.preview.mozCaptureStream;

					return new Promise(resolve => {
						this.$refs.preview.onplaying = resolve;
					});
				}).then(() => {
					this.recorder = new MediaRecorder(this.$refs.preview.captureStream());
					this.recorder.ondataavailable = e => this.videoChunks.push(e.data);
					this.recorder.onstop = () => {
						clearInterval(this.recordingInteval);
						this.recording = false;
						this.videoBlob = new Blob(this.videoChunks, {type: 'video/webm'});

						this.$refs.preview.srcObject = null;
						this.$refs.preview.removeAttribute('autoplay');
						this.$refs.preview.src = URL.createObjectURL(this.videoBlob);
						this.showOverlay = true;

						this.beginUpload();
					};
					this.recorder.onstart = () => {
						this.recording = true;
						this.showOverlay = false;

						if(this.block.meta.nrOfTries) {
							this.triesRemaning -= 1;
						}

						this.recordingInteval = setInterval(() => {
							this.timeLimit -= 1;

							if(this.timeLimit < 0) {
								this.recorder.stream.getTracks().forEach(track => track.stop());
								this.recorder.stop();
							}
						}, 1000);
					};
				});
			},
			record() {
				if(this.recording) {
					this.recorder.stream.getTracks().forEach(track => track.stop());
					this.recorder.stop();
				}
				else if(this.block.meta.nrOfTries && !this.triesRemaning) {
					Bus.emit('error', 'You have can\'t upload any more files to this question.');
				}
				else {
					this.setupVideoRecorder().then(() => {
						this.videoChunks = [];
						this.videoBlob = null;
						this.timeLimit = this.block.meta.timeLimit;

						this.recorder.start();
					}).catch(err => {
						Bus.emit('error', err.message);
					});
				}
			},
			play() {
				if(this.$refs.preview.playing) {
					this.showOverlay = true;
					this.$refs.preview.pause();
				}
				else {
					this.$refs.preview.onended = () => {
						this.showOverlay = true;
					};
					this.showOverlay = false;
					this.$refs.preview.play();
				}
			},
			beginUpload() {
				const filename = 'user-video-' + Store.user.id + '-' + (DateTime.utc().toFormat('y-MM-dd-HH:mm:ss')) + '.webm';
				const file = new File(this.videoChunks, filename, {type: 'video/webm'});

				this.filesChange([file]);
			}
		}
	};
</script>

<style lang="scss">
	.block.recvideo {
		.recording {
			.video-background button {
				background-color: $color__red;
				padding: 20px;

				&:hover {
					background-color: darken($color__red, 5%);
				}

				svg {
					display: none;
				}

				&::before {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					background-color: $color__white;
				}
			}
		}

		.has-video {
			.video-background-overlay {
				cursor: pointer;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;
					border-style: solid;
					border-color: transparent;
					border-width: 30px 40px;
					border-left-color: rgba($color: $color__white, $alpha: 0.3);
					transform: translate(-18%, -50%);
					transition: border-left-color .2s ease;
				}

				&:hover::before {
					border-left-color: rgba($color: $color__white, $alpha: 0.8);
				}
			}
		}

		.video-background {
			position: relative;
			height: 400px;
			background-color: $color__white;
			border-radius: $border_radius;

			video {
				width: 100%;
				height: 100%;
				-webkit-transform: scaleX(-1);
				transform: scaleX(-1);
			}

			button {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 50%);
				border-radius: 50%;
				background-color: $color__light_blue;
				color: $color__white;
				padding: 14px;
				border: 3px solid $color__background;
				transition: background-color .2s ease;
				outline: none;
				z-index: 2;

				&:hover {
					background-color: $color__blue;
				}

				svg {
					display: block;
				}
			}
		}

		.meta {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
		}

		.video-background,
		.meta {
			max-width: 600px;
		}
	}
</style>